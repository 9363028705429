import React from 'react'
import Button, { IButtonProps } from '@/components/button'

const ButtonPrimary: React.FC<
    IButtonProps & React.HTMLAttributes<HTMLButtonElement>
> = (props) => {
    const children = props.children
    const className = props.className

    return (
        <Button
            {...props}
            className={`btn-gradient-primary${
                className ? ' ' + className : ''
            }`}
        >
            {children}
        </Button>
    )
}

export default ButtonPrimary
