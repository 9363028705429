import React, { ReactNode } from 'react'
import { tuple } from '@/components/utils/type'
import classNames from 'classnames'
import Loader from '../loader'

const buttonTypes = tuple('default', 'primary', 'dashed', 'link', 'text')
type ButtonType = typeof buttonTypes[number]

const sizeTypes = tuple('small', 'middle', 'large')
type SizeType = typeof sizeTypes[number]

const htmlTypes = tuple('button', 'submit', 'reset')
type HTMLType = typeof htmlTypes[number]

const shapeTypes = tuple('default', 'circle', 'round')
type ShapeType = typeof shapeTypes[number]

const iconDirectionTypes = tuple('top', 'bottom', 'left', 'right')
type IconDirectionTypes = typeof iconDirectionTypes[number]
export interface IButtonProps {
    danger?: boolean
    hover?: boolean
    shadow?: boolean
    type?: ButtonType
    className?: string
    size?: SizeType
    disabled?: boolean
    htmlType?: HTMLType
    shape?: ShapeType
    icon?: string
    loading?: boolean
    iconDirection?: IconDirectionTypes
    children?: ReactNode | string
    classNameIcon?: string
}

const Button: React.FC<
    IButtonProps & React.HTMLAttributes<HTMLButtonElement>
> = ({
    danger,
    hover,
    shadow,
    type,
    className,
    size,
    disabled,
    htmlType,
    shape,
    icon,
    loading,
    iconDirection,
    children,
    classNameIcon,
    ...rest
}) => {
    const classes = classNames(
        'btn',
        {
            'btn-danger': !!danger,
            'btn-shadow': !!shadow,
            'btn-hover': !!hover,
            [`btn-${type}`]: type !== 'default' && type,
            [`btn-${size}`]: size !== 'middle' && size,
            [`btn-${htmlType}`]: htmlType,
            [`btn-${shape}`]: shape !== 'default' && shape,
            [`btn-icon-${iconDirection}`]:
                iconDirection !== 'left' && iconDirection,
        },
        className,
    )

    return (
        <button
            type={htmlType}
            disabled={disabled}
            className={classes}
            {...rest}
        >
            {icon && (
                <img
                    src={icon}
                    className={`btn-icon ${classNameIcon || ''}`}
                    alt="icon-button-error"
                />
            )}
            <span>{children}</span>
            {loading && <Loader className="ml-auto" />}
        </button>
    )
}

export default Button
