import React from 'react'
import Layout from '@/layout/'
import { Route } from 'wouter'

const PublicRoute = ({ component: Component, ...rest }: any) => {
    const renderChildren = (props: any) => (
        <Layout>
            <Component {...props} />
        </Layout>
    )
    return (
        <>
            <Route {...rest}>{renderChildren({})}</Route>
        </>
    )
}

export default PublicRoute
