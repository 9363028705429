import React, { lazy, ReactElement, useEffect } from 'react'
import { useLocation } from 'wouter'

const LandingPageHeader = lazy(() => import('@/layout/header'))
const Footer = lazy(() => import('@/layout/footer'))
interface ILayout {
    children: ReactElement
}

const Layout: React.FC<ILayout> = ({ children }) => {
    const [location] = useLocation()
    useEffect(() => {
        window.scroll({ top: 0, left: 0, behavior: 'auto' })
    }, [location])

    return (
        <div>
            <LandingPageHeader />
            <main>{children}</main>
            <Footer />
        </div>
    )
}

export default Layout
