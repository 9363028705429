import React from 'react'
import * as ReactDOM from 'react-dom/client'
import '@/styles/app.scss'
import '@/index.css'
import App from '@/App'
import reportWebVitals from '@/reportWebVitals'
const rootNode = document.getElementById('root') as HTMLElement

ReactDOM.createRoot(rootNode).render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
)

reportWebVitals()
