import React, { lazy, Suspense } from 'react'
import PublicRoute from '@/react-routes/public-route'
import { Redirect, Route, Router } from 'wouter'

const Components: any = {
    LandingPage: lazy(() => import('@/pages/landing')),
    FAQ: lazy(() => import('@/pages/faq')),
}

const LazyComponent = ({ component = 'LandingPage', ...props }) => {
    const View = Components[component] || Components['LandingPage']
    return (
        <Suspense fallback={false}>
            <View {...props} />
        </Suspense>
    )
}
const DefaultRedirect = () => <Redirect to="/" />

const Routes = () => {
    return (
        <Suspense fallback={false}>
            <Router>
                <PublicRoute
                    path="/"
                    component={() => <LazyComponent component="LandingPage" />}
                />
                <PublicRoute
                    path="/about/faq"
                    component={() => <LazyComponent component="FAQ" />}
                />
                <Route path="/:rest*" component={DefaultRedirect} />
            </Router>
        </Suspense>
    )
}

export default Routes
