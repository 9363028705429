const REACT_APP_BSC_BASE_URL = process.env.REACT_APP_BSC_BASE_URL
const REACT_APP_KLAY_BASE_URL = process.env.REACT_APP_KLAY_BASE_URL
import KlayIcon from '@/assets/images/klaytn-icon.png'
import BinanceIcon from '@/assets/images/binance-icon.png'

export const NETWORKS_LIST = [
    {
        name: 'Binance Smart Chain',
        baseUrl: REACT_APP_BSC_BASE_URL,
        icon: BinanceIcon,
    },
    {
        name: 'Klaytn Cypress',
        baseUrl: REACT_APP_KLAY_BASE_URL,
        icon: KlayIcon,
    },
]
