import classNames from 'classnames'
import React from 'react'

interface ILoader {
    className?: string
}

const Loader = (props: ILoader) => {
    const { className } = props
    const classes = classNames('loader', className)

    return <div className={classes}></div>
}

export default Loader
