import React, { useRef, useEffect } from 'react'
import XIcon from '@/assets/svgs/xwhite.svg'

interface IProps {
    id: string
    renderHeader: (() => any) | null | undefined
    children: any
    closeBtn?: boolean
    closeWhenClickOutside?: boolean
    className?: string
    classNameParent?: string
    renderFooter?: () => any
}
export const toggleModal = (id: string) => {
    const modal = document.getElementById(id)
    if (!!modal) {
        const modalInner = modal.querySelector('.modal')
        if (modal.classList.contains('open')) {
            // close

            if (modalInner) {
                modalInner.classList.add('out')
            }
            setTimeout(() => {
                modal.classList.remove('open')
                if (modalInner) {
                    modalInner.classList.remove('out')
                }
            }, 350)
        } else {
            // open
            modal.classList.add('open')
        }
    }
}

export const openModal = (id: string) => {
    const modal = document.getElementById(id)
    if (!!modal) {
        modal.classList.add('open')
    }
}

export const closeModal = (id: string) => {
    const modal = document.getElementById(id)
    if (!!modal) {
        const modalInner = modal.querySelector('.modal')
        if (modal.classList.contains('open')) {
            // close

            if (modalInner) {
                modalInner.classList.add('out')
            }
            setTimeout(() => {
                modal.classList.remove('open')
                if (modalInner) {
                    modalInner.classList.remove('out')
                }
            }, 350)
        }
    }
}

function Modal(props: IProps) {
    const {
        closeBtn = false,
        closeWhenClickOutside = true,
        id,
        className = 'w-[600px]',
        classNameParent,
    } = props
    const modalWraperRef = useRef<HTMLDivElement | null>(null)
    const modalRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        if (closeWhenClickOutside && modalWraperRef && modalWraperRef.current) {
            const f = (e: any) => {
                if (
                    modalWraperRef &&
                    modalWraperRef.current &&
                    modalRef &&
                    modalRef.current
                ) {
                    if (
                        modalRef &&
                        modalRef.current &&
                        !modalRef.current.contains(e.target)
                    ) {
                        toggleModal(id)
                    }
                }
            }
            modalWraperRef.current.addEventListener('mousedown', f)
        }
    }, [modalWraperRef, modalRef, id])

    return (
        <div
            id={props.id}
            ref={modalWraperRef}
            className={`modal-wraper ${classNameParent || ''}`}
        >
            <div ref={modalRef} className={`modal bg-bg-dark-04 ${className}`}>
                {!!props.renderHeader && (
                    <div className="modal__header">{props.renderHeader()}</div>
                )}
                <div className="modal__body">{props.children}</div>
                {!!props.renderFooter && (
                    <div className="modal__footer">{props.renderFooter()}</div>
                )}
                {closeBtn && (
                    <img
                        src={XIcon}
                        onClick={() => toggleModal(id)}
                        className="absolute top-[1rem] right-[1rem] w-[16px] h-[16px] cursor-pointer hover:opacity-80"
                        alt=""
                    />
                )}
            </div>
        </div>
    )
}

Modal.propTypes = {}

export default Modal
