import React from 'react'

import Routes from '@/react-routes/routes'
import ChooseNetworkModal from './components/modal/choose-network-modal'

function App() {
    return (
        <>
            <ChooseNetworkModal
                id="choose-network-lend-collateral"
                url="/lend/collateral"
            />
            <ChooseNetworkModal
                id="choose-network-lend-loans"
                url="/lend/loans"
            />
            <ChooseNetworkModal
                id="choose-network-lend-offers"
                url="/lend/offers"
            />
            <ChooseNetworkModal
                id="choose-network-borrow-assets"
                url="/borrow/assets"
            />
            <ChooseNetworkModal
                id="choose-network-borrow-loans"
                url="/borrow/loans"
            />
            <ChooseNetworkModal
                id="choose-network-borrow-offers"
                url="/borrow/offers"
            />
            <ChooseNetworkModal id="choose-network-stats" url="/stats" />
            <ChooseNetworkModal id="choose-network-account" url="/account" />
            <Routes />
        </>
    )
}

export default App
