import React from 'react'
import Modal from '.'

import ButtonPrimary from '../button/button-primary'
import { NETWORKS_LIST } from '@/constants/network'

interface IChooseNetworkModal {
    url: string
    id: string
}

const ChooseNetworkModal = ({ url, id }: IChooseNetworkModal) => {
    return (
        <Modal
            id={id}
            renderHeader={() => (
                <span className="md:text-base">Choose network</span>
            )}
            className="!px-10 w-[40rem] !pb-10 md:!p-4 overflow-y-scroll max-h-[75%]"
        >
            {NETWORKS_LIST.map((network, index) => (
                <div
                    key={index}
                    className="flex flex-row md:flex-wrap px-6 md:p-4 py-3 border border-white border-opacity-[0.15] bg-bg-content rounded-lg mb-4 items-center"
                >
                    <div className="w-6 md:w-5 h-6 md:h-5 rounded-full bg-text-01 mr-[15px]">
                        <img
                            src={network.icon}
                            alt="network-icon-alt"
                            className="overflow-hidden rounded-full"
                        />
                    </div>

                    <div className="flex-1 text-lg">
                        <p className="text-text-01 font-semibold md:text-base">
                            {network.name}
                        </p>
                    </div>

                    <div className="ml-11 md:ml-0 md:mt-4 md:w-full">
                        <a href={`${network.baseUrl}${url}`}>
                            <ButtonPrimary className="px-[33px] py-4 leading-7 md:w-full text-btn">
                                Join
                            </ButtonPrimary>
                        </a>
                    </div>
                </div>
            ))}
        </Modal>
    )
}

export default ChooseNetworkModal
